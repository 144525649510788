import { formValueSelector, getFormSyncErrors } from 'redux-form'
import { createSelector } from 'reselect'
import { getCardFieldsStatuses } from 'src/common/Form/Stripe/Redux/Data'
import { isBlank } from 'src/common/ObjectHelper'
import { CHECKOUT_FORM } from '../../Checkout/Checkout'
import { dummyData } from '../dummyData'

export const FORM_LINE_ITEMS_NAMESPACE = 'lineItems'

export const getLineItemTemplates = createSelector(
  state => fetchLineItems,
  lineItemTemplates => {
    return lineItemTemplates || {}
  }
)

export const getUnitCounts = createSelector(
  state => formValueSelector(CHECKOUT_FORM)(state, FORM_LINE_ITEMS_NAMESPACE),
  unitCounts => {
    return unitCounts || {}
  }
)

export const getTotalUnitCount = createSelector(
  getUnitCounts,
  unitCounts => {
    var totalUnitCount = 0

    Object.keys(unitCounts).forEach(lineItemId => {
      const numUnits = parseInt(unitCounts[lineItemId], 10)

      if (numUnits !== 0) {
        totalUnitCount += numUnits
      }
    })

    return totalUnitCount
  }
)

export const getAddServicesStepCompleted = createSelector(
  getTotalUnitCount,
  totalUnitCount => {
    return totalUnitCount > 0
  }
)

export const getCustomerServiceInfoStepCompleted = createSelector(
  state => getFormSyncErrors(CHECKOUT_FORM)(state),
  errors => {
    return (
      isBlank(errors.firstName) &&
      isBlank(errors.lastName) &&
      isBlank(errors.phoneNumber) &&
      isBlank(errors.email) &&
      isBlank(errors.streetAddress) &&
      isBlank(errors.city) &&
      isBlank(errors.state)
    )
  }
)

export const getJobSchedulingInfoStepCompleted = createSelector(
  state => getFormSyncErrors(CHECKOUT_FORM)(state),
  errors => {
    return isBlank(errors.startTime) && isBlank(errors.endTime)
  }
)

export const getPaymentInfoStepCompleted = createSelector(
  state => getCardFieldsStatuses(state),
  cardFieldStatuses => {
    return (
      cardFieldStatuses.cardNumber &&
      cardFieldStatuses.cardExpiry &&
      cardFieldStatuses.cardCvc &&
      cardFieldStatuses.postalCode
    )
  }
)

export const getTotalCost = createSelector(
  [getLineItemTemplates, getUnitCounts],
  (lineItems, unitCounts) => {
    var totalCost = 0

    Object.keys(unitCounts).forEach(lineItemId => {
      const numUnits = parseInt(unitCounts[lineItemId], 10)
      const costPerUnit = lineItems[lineItemId].cost

      if (numUnits !== 0) {
        totalCost += numUnits * costPerUnit
      }
    })

    return totalCost
  }
)

export const fetchLineItems = dummyData
