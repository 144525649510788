import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PersonalInformation from '../CustomerServiceInformation/PersonalInformation'
import AddressInformation from '../CustomerServiceInformation/AddressInformation'
import Section from '../Sections/Section'
import BookingWindowsSelection from '../BookingWindowsSelection/BookingWindowsSelection'
import { CHECKOUT_FORM } from '../Checkout'

const styles = theme => ({
  container: {
    paddingTop: 80,
    paddingLeft: 12,
    paddingRight: 12,
  },
})

function MobileCheckoutForm({ classes, disableForm, streetAddress }) {
  return (
    <Grid container spacing={16} spacing={24} className={classes.container}>
      <Grid item xs={12}>
        <Section
          sectionNumber={1}
          title="Add your contact info"
          content={<PersonalInformation disabled={disableForm} />}
          isMobile
        />
      </Grid>
      <Grid item xs={12}>
        <Section
          sectionNumber={2}
          title="Select your service call window"
          content={<BookingWindowsSelection disabled={disableForm} />}
          isMobile
        />
      </Grid>
      <Grid item xs={12}>
        <Section
          sectionNumber={3}
          title="Service information"
          content={
            <AddressInformation
              disabled={disableForm}
              reduxFormName={CHECKOUT_FORM}
              showAdditionalNotes={true}
              streetAddress={streetAddress}
            />
          }
          isMobile
        />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(MobileCheckoutForm)
