import RestAPI from 'src/common/RestApi'

export function updateLogoApi(logoFile) {
  let formData = new FormData()
  formData.append('file', logoFile)
  return RestAPI.updateWithFormData('/alpha/organization/logo', formData)
}

export function deleteLogoApi() {
  return RestAPI.deleteWithFormData('/alpha/organization/logo')
}
