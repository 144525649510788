import React from 'react'
import Grid from '@material-ui/core/Grid'
import BeatLoader from 'react-spinners/BeatLoader'
import { blue } from '@material-ui/core/colors'

function BounceSpinner() {
  return (
    <div style={{ textAlign: 'center' }}>
      <BeatLoader color={blue[500]} size={16} />
    </div>
  )
}

export default BounceSpinner
