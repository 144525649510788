import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
  tabs: {
    background: '#ffffff',
    alignSelf: 'flex-end',
  },
  tab: {
    minWidth: 100,
    minHeight: 50,
    fontSize: 14,
    color: '#000',
    fontWeight: 'normal',
    textTransform: 'none',
    opacity: 1,
  },
})

@withStyles(styles)
export default class NavigationActions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { value: props.selectedValue }
  }

  render() {
    const {
      classes,
      showAboutUs,
      showFAQ,
      showPhotos,
      hasAndShowReviews,
      showServiceArea,
      showTestimonials,
    } = this.props
    return (
      <Tabs
        classes={{ root: classes.tabs }}
        value={this.state.value}
        onChange={(event, value) => {
          this.setState({ value })
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {showAboutUs && (
          <Tab label="About us" href="#about" className={classes.tab} />
        )}
        {showServiceArea && (
          <Tab
            label="Service area"
            href="#service_area"
            className={classes.tab}
          />
        )}
        {showTestimonials && (
          <Tab
            label="Testimonials"
            href="#testimonials"
            className={classes.tab}
          />
        )}
        {hasAndShowReviews && (
          <Tab label="Reviews" href="#reviews" className={classes.tab} />
        )}
        {showFAQ && <Tab label="FAQ" href="#faq" className={classes.tab} />}
        {showPhotos && (
          <Tab label="Photos" href="#photos" className={classes.tab} />
        )}
        <Hidden mdUp>
          <Tab disabled={true} />
        </Hidden>
      </Tabs>
    )
  }
}

NavigationActions.props = {
  showFAQ: PropTypes.bool.isRequired,
  showPhotos: PropTypes.bool.isRequired,
  showServiceArea: PropTypes.bool.isRequired,
  showAboutUs: PropTypes.bool.isRequired,
  showTestimonials: PropTypes.bool.isRequired,
  showReviews: PropTypes.bool.isRequired,
  selectedValue: PropTypes.number,
}

NavigationActions.defaultProps = {
  showFAQ: false,
  showPhotos: false,
  showServiceArea: false,
  showAboutUs: false,
  showTestimonials: false,
  showReviews: false,
  selectedValue: 0,
}
