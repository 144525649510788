import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { isPresent } from 'src/common/ObjectHelper'
import NavigationActions from './NavigationActions'
import { AppBar, Typography } from '@material-ui/core'
import { format } from 'src/common/PhoneNumberFormater'
import CompanyLogoParent from '../CompanyProfile/CompanyLogo/CompanyLogoParent'

const styles = theme => ({
  container: {
    height: 100,
    paddingLeft: 16,
    paddingRight: 16,
  },
  navigationSection: {
    height: '100%',
  },
  bookUsButton: {
    paddingLeft: 30,
  },
  phoneText: {
    textDecoration: 'none',
  },
})

function simulateClickOnDatePicker() {
  let datePicker = document.getElementById('BookingCalendarDatePicker')
  datePicker.focus()
  datePicker.click()
}

function Header({
  classes,
  logoUrl,
  companyName,
  phoneNumber,
  stickyHeader,
  showActions,
  inEditMode,
  showAboutUs,
  showFAQ,
  showReviews,
  hasReviews,
  showPhotos,
  showServiceArea,
  showTestimonials,
  showBookOnline,
}) {
  let position = 'fixed'
  let marginBottom = 108
  if (stickyHeader === false) {
    position = 'relative'
    marginBottom = 0
  }

  let justify = showActions ? 'flex-start' : 'space-between'

  return (
    <div style={{ marginBottom: marginBottom }}>
      <AppBar color="secondary" position={position}>
        <Grid
          container
          alignItems="center"
          className={classes.container}
          justify={justify}
        >
          <Grid item>
            <CompanyLogoParent
              logoUrl={logoUrl}
              inEditMode={inEditMode}
              companyName={companyName}
            />
          </Grid>
          {showActions && (
            <Grid
              item
              container
              xs
              className={classes.navigationSection}
              alignItems="flex-end"
            >
              <Grid item>
                <NavigationActions
                  showFAQ={showFAQ}
                  showPhotos={showPhotos}
                  showAboutUs={showAboutUs}
                  hasAndShowReviews={hasReviews && showReviews}
                  showServiceArea={showServiceArea}
                  showTestimonials={showTestimonials}
                />
              </Grid>
            </Grid>
          )}
          <Grid item>
            {isPresent(phoneNumber) && (
              <a className={classes.phoneText} href={'tel:' + phoneNumber}>
                <Typography variant="h6">{format(phoneNumber)}</Typography>
              </a>
            )}
          </Grid>
          {showBookOnline && (
            <Grid item className={classes.bookUsButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => simulateClickOnDatePicker()}
              >
                Book us online
              </Button>
            </Grid>
          )}
        </Grid>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  logoUrl: PropTypes.string,
  phoneNumber: PropTypes.string,
  stickyHeader: PropTypes.bool,
  showActions: PropTypes.bool,
  showFAQ: PropTypes.bool.isRequired,
  showPhotos: PropTypes.bool.isRequired,
  showServiceArea: PropTypes.bool.isRequired,
  showAboutUs: PropTypes.bool.isRequired,
  showTestimonials: PropTypes.bool.isRequired,
  showBookOnline: PropTypes.bool,
  showReviews: PropTypes.bool.isRequired,
  companyName: PropTypes.string,
}

Header.defaultProps = {
  logoUrl: '',
  phoneNumber: '',
  stickyHeader: true,
  showActions: true,
  showFAQ: false,
  showPhotos: false,
  showServiceArea: false,
  showAboutUs: false,
  showTestimonials: false,
  showBookOnline: true,
  showReviews: false,
  companyName: '',
}

export default withStyles(styles)(Header)
