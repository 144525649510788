import { createJobApi, createCustomerApi, scheduleJobApi } from './Api'
import { formValueSelector, change } from 'redux-form'
import { CHECKOUT_FORM } from '../Checkout'
import {
  validatePresence,
  emailFormValidator,
  phoneNumberFormValidator,
} from 'src/common/Form/FormValidations'
import { updateCustomerInfoState } from '../../Redux/CustomerInfo/Data'
import { isPresent } from 'src/common/ObjectHelper'

const ONLINE_BOOKING_ENTRY_POINT = 'Pro Website'

export function validate(values) {
  const errors = {}

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    streetAddress,
    city,
    state,
    startTime,
    endTime,
  } = values

  errors.firstName = validatePresence(firstName)
  errors.lastName = validatePresence(lastName)
  errors.phoneNumber =
    validatePresence(phoneNumber) || phoneNumberFormValidator(phoneNumber)
  errors.email = validatePresence(email) || emailFormValidator(email)
  errors.streetAddress = validatePresence(streetAddress)
  errors.city = validatePresence(city)
  errors.state = validatePresence(state)
  errors.startTime = validatePresence(startTime)
  errors.endTime = validatePresence(endTime)

  return errors
}

export function updateScheduleTimesOnForm(selectedWindow) {
  return dispatch => {
    if (isPresent(selectedWindow)) {
      dispatch(change(CHECKOUT_FORM, 'startTime', selectedWindow.start_time))
      dispatch(change(CHECKOUT_FORM, 'endTime', selectedWindow.end_time))
    } else {
      dispatch(change(CHECKOUT_FORM, 'startTime', null))
      dispatch(change(CHECKOUT_FORM, 'endTime', null))
    }
  }
}

export function createCustomer(values) {
  const addresses = [
    {
      contact_first_name: values.firstName,
      contact_last_name: values.lastName,
      contact_phone: values.phoneNumber,
      city: values.city,
      state: values.state,
      street: values.streetAddress,
      street_line_2: values.streetLineTwo,
      zip: values.postalCode,
      country: values.country,
    },
  ]

  const customerData = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    mobile_number: values.phoneNumber,
    display_name: `${values.firstName} ${values.lastName}`,
    addresses: addresses,
  }

  return dispatch => {
    dispatch(updateCustomerInfoState(customerData))
    return createCustomerApi(customerData)
  }
}

export function createJob(customerDetails) {
  return (dispatch, getState) => {
    const note = formValueSelector(CHECKOUT_FORM)(getState(), 'additionalNotes')

    const jobData = {
      customer: customerDetails.data.id,
      address_id: customerDetails.data.addresses.data[0].id,
      note: note,
      entry_point: ONLINE_BOOKING_ENTRY_POINT
    }

    return createJobApi(jobData)
  }
}

export function scheduleJob(jobUuid) {
  return (dispatch, getState) => {
    const startTime = formValueSelector(CHECKOUT_FORM)(getState(), 'startTime')
    const endTime = formValueSelector(CHECKOUT_FORM)(getState(), 'endTime')
    let data = {
      start_time: startTime,
      end_time: endTime,
      notify: true,
    }

    return scheduleJobApi(jobUuid, data)
  }
}
