import React, { useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withMobileDialog,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import UploadImage from './UploadImage'
import DeleteButton from 'src/common/DeleteButton'
import { ConfirmationDialog, CancelSaveButtons } from 'housecall-ui'
import PhotographyRequest from 'src/common/PhotographyRequest'
import DesignIcon from '../../../../images/99-design.png'
import { newLogoRequest } from '../../../Redux/SendToSlack/Actions'

function ImageEditDialog({
  imageUrl,
  title,
  open,
  onClose,
  onSave,
  onDelete,
  onUpload,
  onConfirmCancel,
  onConfirmDelete,
  fullScreen,
  saving,
  showConfirmation,
  updatedImg,
  id,
}) {
  const dispatch = useDispatch()

  const dispatchNewLogoRequest = useCallback(
    () => dispatch(newLogoRequest()),
    [dispatch]
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <PhotographyRequest
              icon={<img src={DesignIcon} width={80} />}
              textTitle="Get a professional logo - from $299"
              textBody="We've helped 1000's of Home Service Pros with their branding. Choose from dozens of options
                            made just for you. Get a logo you'll love, or your money back!"
              ctaText="Buy"
              onClick={dispatchNewLogoRequest}
              href="https://99designs.com/housecall-pro"
              target="blank"
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImage
              imageUrl={imageUrl}
              onChange={e => onUpload(e)}
              id={id}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <DeleteButton
              onDeleteClick={() => onDelete()}
              isSaving={saving}
            />
          </Grid>
          <Grid item>
            <CancelSaveButtons
              saveButtonVariant="text"
              onCancelClick={() => onClose()}
              onSaveClick={() => onSave()}
              isSaving={saving}
              disabled={!updatedImg}
            />
          </Grid>
        </Grid>
      </DialogActions>
      <ConfirmationDialog
        open={showConfirmation}
        message_title="Are you sure you want to delete?"
        message_body=""
        cancelButtonMessage="No"
        confirmButtonMessage="Yes"
        onCancelClick={() => onConfirmCancel()}
        onConfirmClick={() => onConfirmDelete()}
      />
    </Dialog>
  )
}

ImageEditDialog.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  updatedImg: PropTypes.bool,
  id: PropTypes.string,
}

ImageEditDialog.defaultProps = {
  open: false,
  saving: false,
  showConfirmation: false,
  updatedImg: false,
}

export default withMobileDialog()(React.memo(ImageEditDialog))
