import { format } from 'date-fns/esm'
import { getBookingWindowsForDateApi } from './Api'
import { updateBookingWindowsState } from './Data'
import { isPresent } from 'src/common/ObjectHelper'

export function beginningOfDayInCurrentTimeZone(date) {
  return new Date(date.replace(/-/g, '/').replace(/T.+/, ''))
}

export function updateBookingWindows(date, bookingWindows) {
  return dispatch => {
    dispatch(
      updateBookingWindowsState({
        serviceDate: beginningOfDayInCurrentTimeZone(date),
        bookingWindows: bookingWindows,
      })
    )
  }
}

export function getBookingWindowsForDate(date) {
  const dateData = {
    show_for_days: 1,
  }

  if (isPresent(date)) {
    let formattedDate = format(date, 'yyyy-MM-dd')
    dateData.start_date = formattedDate
  }

  return dispatch => {
    return getBookingWindowsForDateApi(dateData)
  }
}
