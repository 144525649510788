import { updateLogoApi, deleteLogoApi } from './Api'
import { didUpdateContent } from '../../../Edit/Redux/Actions'

const ORGANIZATION = 'organization'

export function updateLogo(logoFile) {
  return dispatch => {
    return updateLogoApi(logoFile).then(response => {
      dispatch(didUpdateContent(ORGANIZATION, response.data))
    })
  }
}

export function deleteLogo() {
  return dispatch => {
    return deleteLogoApi().then(response => {
      dispatch(didUpdateContent(ORGANIZATION, response.data))
    })
  }
}
