import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import ConfirmationDialog from 'housecall-ui/dist/core/PhotoGridList/ConfirmationDialog'

const styles = theme => ({
  root: {
    backgroundColor: '#fafafa',
    padding: 12,
  },
  button: {
    [theme.breakpoints.only('xs')]: { marginTop: 16 },
    marginLeft: 8,
    marginRight: 8,
  },
  icon: {
    marginRight: 16,
  },
})

@withStyles(styles)
export default class PhotographyRequest extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmationDialog: false,
    }
  }

  render() {
    const { classes, ctaText, icon, textTitle, textBody, disabled, onClick, href, target } = this.props
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.root}
      >
        <Hidden xsDown>
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
        </Hidden>
        <Grid item xs={12} sm>
          <Typography variant="h6">{textTitle}</Typography>
          <Typography variant="subtitle1">{textBody}</Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={disabled}
            onClick={() => {
              onClick()
              this.setState({
                showConfirmationDialog: true,
              })
            }}
            href={href}
            target={target}
          >
            {ctaText}
          </Button>
        </Grid>
        <ConfirmationDialog
          open={this.state.showConfirmationDialog}
          message_title="Thank you!"
          message_body="We will reach out soon (usually within 24hrs) to discuss your request."
          confirmButtonMessage="Done"
          onConfirmClick={() =>
            this.setState({ showConfirmationDialog: false })
          }
        />
      </Grid>
    )
  }
}

PhotographyRequest.propTypes = {
  textTitle: PropTypes.string.isRequired,
  textBody: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ctaText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

PhotographyRequest.defaultProps = {
  disabled: false,
}
