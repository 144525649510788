import React from 'react'
import PropTypes from 'prop-types'
import FormTextField from './FormTextField'
import { cityLabel } from './AddressData'

function CityField(props) {
    const { currentState, disabled } = props

    return (
        <FormTextField
            name='city'
            label={cityLabel(currentState)}
            disabled={disabled}
            fullWidth
        />
    )
}

CityField.propTypes = {
    currentState: PropTypes.string,
    disabled: PropTypes.bool
}

CityField.defaultProps = {
    disabled: false
}

export default CityField
