import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import CompanyLogoParent from '../CompanyProfile/CompanyLogo/CompanyLogoParent'

const styles = theme => ({
  container: {
    background: theme.palette.background.paper,
    height: 100,
  },
})
function HeaderMobile({ classes, logoUrl, companyName, inEditMode }) {
  return (
    <Grid
      container
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12}>
        <CompanyLogoParent
          logoUrl={logoUrl}
          companyName={companyName}
          inEditMode={inEditMode}
          isMobile={true}
        />
      </Grid>
    </Grid>
  )
}

HeaderMobile.propTypes = {
  logoUrl: PropTypes.string,
  phoneNumber: PropTypes.string,
  companyName: PropTypes.string,
}

HeaderMobile.defaultProps = {
  logoUrl: '',
  phoneNumber: '',
  companyName: '',
}

export default withStyles(styles)(HeaderMobile)
