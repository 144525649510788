import React, { PureComponent, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import CompanyLogo from './CompanyLogo'
import ImageEditDialog from '../BannerAndLogo/Edit/ImageEditDialog'
import { connect } from 'react-redux'
import { updateLogo, deleteLogo } from './Redux/Actions'

@connect(
  state => ({}),
  { updateLogo, deleteLogo }
)
class CompanyLogoParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      updatedUrl: this.props.logoUrl,
      editDialogOpen: false,
      saving: false,
      showConfirmation: false,
      updated: false,
    }
  }

  openEditDialog() {
    this.setState({ editDialogOpen: true })
  }

  closeEditDialog() {
    this.setState({ editDialogOpen: false })
  }

  handleUpload(event) {
    this.setState({
      updated: true,
      updatedUrl: URL.createObjectURL(event.target.files[0]),
      updatedPhoto: event.target.files[0],
    })
  }

  openDeleteConfirmation() {
    this.setState({ showConfirmation: true })
  }

  closeDeleteConfirmation() {
    this.setState({ showConfirmation: false })
  }

  handleDelete() {
    this.setState({
      updated: true,
      showConfirmation: false,
      updatedUrl: '',
      updatedPhoto: null,
      saving: true,
    })
    this.props
      .deleteLogo()
      .then(() => {
        this.setState({ editDialogOpen: false })
      })
      .finally(() => {
        this.setState({ saving: false, updated: false })
      })
  }

  handleSave() {
    this.setState({ saving: true })
    this.props
      .updateLogo(this.state.updatedPhoto)
      .then(() => {
        this.setState({ editDialogOpen: false })
      })
      .finally(() => {
        this.setState({ saving: false, updated: false })
      })
  }

  render() {
    const { inEditMode, logoUrl, companyName, isMobile } = this.props
    const { editDialogOpen, updatedUrl, updated } = this.state

    return (
      <Fragment>
        <CompanyLogo
          logoUrl={logoUrl}
          inEditMode={inEditMode}
          onEditButtonClick={() => this.openEditDialog()}
          companyName={companyName}
          isMobile={isMobile}
        />
        <ImageEditDialog
          imageUrl={updatedUrl}
          title="Company Logo"
          open={editDialogOpen}
          fullScreen={isMobile}
          saving={this.state.saving}
          onClose={() => this.closeEditDialog()}
          onSave={() => this.handleSave()}
          onDelete={() => this.openDeleteConfirmation()}
          onUpload={e => this.handleUpload(e)}
          onConfirmCancel={() => this.closeDeleteConfirmation()}
          onConfirmDelete={() => this.handleDelete()}
          showConfirmation={this.state.showConfirmation}
          updatedImg={updated}
          id="upload-logo-img"
        />
      </Fragment>
    )
  }
}

CompanyLogoParent.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  companyName: PropTypes.string,
  isMobile: PropTypes.bool,
}

CompanyLogoParent.defaultProps = {
  logoUrl: '',
  inEditMode: false,
  companyName: '',
  isMobile: false,
}

export default CompanyLogoParent
