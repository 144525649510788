import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const SMALL = 'small'
const LARGE = 'large'

const styles = theme => ({
  headerContainer: {
    paddingBottom: 12,
  },
})

function SectionHeader({ classes, sectionNumber, title, variant }) {
  let numberAvatarSize
  let textVariant
  if (variant === SMALL) {
    numberAvatarSize = 30
    textVariant = 'h6'
  } else if (variant === LARGE) {
    numberAvatarSize = 35
    textVariant = 'h4'
  }

  return (
    <Grid container spacing={16} className={classes.headerContainer}>
      <Grid item>
        <Avatar
          color="primary"
          style={{ height: numberAvatarSize, width: numberAvatarSize }}
        >
          <Typography variant={textVariant}>{sectionNumber}</Typography>
        </Avatar>
      </Grid>
      <Grid item xs>
        <Typography variant={textVariant}>{title}</Typography>
      </Grid>
    </Grid>
  )
}

SectionHeader.propTypes = {
  sectionNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([SMALL, LARGE]),
}

SectionHeader.defaultProps = {
  variant: LARGE,
}

export default withStyles(styles)(SectionHeader)
