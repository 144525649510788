import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ConfirmBookingSummaryCardRow from '../Mobile/ConfirmBookingSummaryCard/ConfirmBookingSummaryCardRow'
import AccessTime from '@material-ui/icons/AccessTime'
import LocationOn from '@material-ui/icons/LocationOn'
import { withStyles } from '@material-ui/core/styles'
import { formatDateAndStartEndTime } from 'src/common/StartEndDateTimeParser'

const styles = theme => ({
  singleSummaryRow: {
    padding: 8,
  },
})

function SummaryWithoutLineItems({
  classes,
  startTime,
  endTime,
  streetAddress,
  city,
  postalCode,
  state,
}) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.singleSummaryRow}>
        <ConfirmBookingSummaryCardRow
          iconComponent={AccessTime}
          text={formatDateAndStartEndTime(startTime, endTime)}
        />
      </Grid>
      <Grid item xs={12} className={classes.singleSummaryRow}>
        <ConfirmBookingSummaryCardRow
          iconComponent={LocationOn}
          text={`${streetAddress}, ${city}, ${state} ${postalCode}`}
        />
      </Grid>
    </Grid>
  )
}

SummaryWithoutLineItems.propTypes = {
  date: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
}

export default withStyles(styles)(SummaryWithoutLineItems)
