import { createSelector } from 'reselect'
import { fetchCurrentProApi } from './Api'
import { isPresent } from '../ObjectHelper'

export const CURRENT_PRO = 'currentPro'

export const getCurrentPro = createSelector(
  state => state[CURRENT_PRO],
  pro => {
    return pro || {}
  }
)

export const getProId = createSelector(
  state => getCurrentPro(state),
  pro => pro.id || ''
)

export const getProUuid = createSelector(
  state => getCurrentPro(state),
  pro => {
    return pro.uuid || null
  }
)

export const proIsFetched = createSelector(
  state => getCurrentPro(state),
  pro => {
    return isPresent(pro) && !pro.loading
  }
)

export const proIsLoggedIn = createSelector(
  state => getCurrentPro(state),
  pro => {
    return isPresent(pro) && !pro.loading && pro.uuid !== 0
  }
)

export const proAvatar = createSelector(
  state => getCurrentPro(state),
  pro => {
    return pro.avatar_thumb_url || ''
  }
)

export const getFirstName = createSelector(
  state => getCurrentPro(state),
  pro => {
    return pro.first_name || ''
  }
)

export const getLastName = createSelector(
  state => getCurrentPro(state),
  pro => {
    return pro.last_name || ''
  }
)

export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName, lastName) => {
    return firstName + ' ' + lastName
  }
)

export const getMobileNumber = createSelector(
  state => getCurrentPro(state),
  pro => {
    return pro.mobile_number || ''
  }
)

export const getProEmail = createSelector(
  state => getCurrentPro(state),
  pro => pro.email || ''
)

export const fetchCurrentPro = {
  fetchingApiFunc: fetchCurrentProApi,
  selector: getCurrentPro,
  dataNamespace: CURRENT_PRO,
}
