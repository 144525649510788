import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import ServiceDateTextField from '../../CompanyProfile/Book/ServiceDateTextField'
import {
  getSelectedBookingWindow,
  clearSelectedBookingWindowState,
} from '../../Redux/SelectedBookingWindow/Data'
import { updateSelectedBookingWindow } from '../../Redux/SelectedBookingWindow/Actions'
import {
  getServiceDate,
  getBookingWindows,
} from '../../Redux/BookingWindowsForSelectedDate/Data'
import {
  getBookingWindowsForDate,
  updateBookingWindows,
} from '../../Redux/BookingWindowsForSelectedDate/Actions'
import { updateScheduleTimesOnForm } from '../Redux/Actions'
import BookingWindowsSelect from './BookingWindowsSelect'
import PropTypes from 'prop-types'
import { isPresent } from 'src/common/ObjectHelper'

@connect(
  state => ({
    serviceDate: getServiceDate(state),
    selectedBookingWindow: getSelectedBookingWindow(state),
    bookingWindows: getBookingWindows(state),
  }),
  {
    getBookingWindowsForDate,
    updateSelectedBookingWindow,
    updateBookingWindows,
    clearSelectedBookingWindowState,
    updateScheduleTimesOnForm,
  }
)
export default class BookingWindowsSelection extends PureComponent {
  handleSelectedWindow(selectedWindow) {
    const {
      updateScheduleTimesOnForm,
      updateSelectedBookingWindow,
    } = this.props

    updateScheduleTimesOnForm(selectedWindow)
    updateSelectedBookingWindow(selectedWindow)
  }

  fetchWindowsForDate(date) {
    const {
      getBookingWindowsForDate,
      updateBookingWindows,
      clearSelectedBookingWindowState,
      updateScheduleTimesOnForm,
    } = this.props

    getBookingWindowsForDate(date).then(response => {
      clearSelectedBookingWindowState()
      updateScheduleTimesOnForm(null)

      let bookingWindows = response.data.booking_windows.data
      let selectedBookingWindow = isPresent(bookingWindows)
        ? bookingWindows[0]
        : {}

      updateBookingWindows(response.data.start_date, bookingWindows)
      this.handleSelectedWindow(selectedBookingWindow)
    })
  }

  render() {
    const {
      selectedBookingWindow,
      serviceDate,
      bookingWindows,
      disabled,
    } = this.props

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <ServiceDateTextField
            serviceDate={serviceDate}
            dateSelected={date => this.fetchWindowsForDate(date)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {serviceDate && (
            <BookingWindowsSelect
              disabled={disabled}
              selectedBookingWindow={selectedBookingWindow}
              bookingWindows={bookingWindows}
              onWindowSelected={selectedBookingWindow =>
                this.handleSelectedWindow(selectedBookingWindow)
              }
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

BookingWindowsSelection.propTypes = {
  disabled: PropTypes.bool,
}

BookingWindowsSelection.defaultProps = {
  disabled: false,
}
