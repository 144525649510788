import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { IconButton, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CompanyLogoOrName from './CompanyLogoOrName'

function CompanyLogo({ logoUrl, companyName, inEditMode, onEditButtonClick }) {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <CompanyLogoOrName
          variant="large"
          logoUrl={logoUrl}
          companyName={companyName}
          inEditMode={inEditMode}
        />
      </Grid>
      {inEditMode && (
        <Grid item>
          <Tooltip title="Edit">
            <IconButton onClick={() => onEditButtonClick()}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

CompanyLogo.propTypes = {
  logoUrl: PropTypes.string,
  companyName: PropTypes.string,
  inEditMode: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
}

CompanyLogo.defaultProps = {
  logoUrl: '',
  companyName: '',
  inEditMode: false,
  onEditButtonClick: () => {},
}

export default CompanyLogo
