import React, { Fragment } from 'react'
import { Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'


const styles = theme => ({
    deleteButton: {
        color: theme.palette.button.destructive,
        '&:hover': {
        backgroundColor: theme.palette.button.destructiveLightHover,
        },
    },
})

function DeleteButton({ classes, onDeleteClick, isSaving }) {
    return (
        <Button
            className={classes.deleteButton}
            disabled={isSaving}
            onClick={() => onDeleteClick()}
        >
            Delete
        </Button>
    )
}

DeleteButton.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    hidden: PropTypes.bool
}

DeleteButton.defaultProps = {
    isSaving: false,
    hidden: false
}

export default withStyles(styles)(DeleteButton)
