import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { Redirect } from '@reach/router'
import { isBlank } from 'src/common/ObjectHelper'
import {
  getServiceCity,
  getServiceState,
  getServiceStreetAddress,
  getServicePostalCode,
  getServiceCountry,
  getServiceLatitude,
  getServiceLongitude,
} from '../Redux/ServiceLocation/Data'
import { getStartTime, getEndTime } from '../Redux/SelectedBookingWindow/Data'
import {
  createCustomer,
  createJob,
  scheduleJob,
  validate,
} from './Redux/Actions'
import WebCheckoutContent from './Web/WebCheckoutContent'
import MobileCheckoutContent from './Mobile/MobileCheckoutContent'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import { navigate } from 'gatsby'
import { getOrganization } from 'src/Resources/Organization/Data'

export const CHECKOUT_FORM = 'CHECKOUT_FORM'

const styles = theme => ({
  form: {
    background: '#FAFAFA',
    minHeight: 'calc(100vh - 120px)',
    width: 'calc(100% + 8px)',
    paddingTop: 80,
  },
})

@connect(
  state => ({
    initialValues: {
      lineItems: {
        0: 1,
      },
      startTime: getStartTime(state),
      endTime: getEndTime(state),
      streetAddress: getServiceStreetAddress(state),
      streetLineTwo: null,
      city: getServiceCity(state),
      state: getServiceState(state),
      postalCode: getServicePostalCode(state),
      country: getServiceCountry(state),
      latitude: getServiceLatitude(state),
      longitude: getServiceLongitude(state),
    },
    organization: getOrganization(state),
    updatedStreet: formValueSelector(CHECKOUT_FORM)(state, 'streetAddress'),
  }),
  { createCustomer, createJob, scheduleJob }
)
@reduxForm({
  form: CHECKOUT_FORM,
  validate,
})
@withStyles(styles)
export default class Checkout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { disableForm: false }
  }

  submitForm(values) {
    this.setState({ disableForm: true })
    
    const { createCustomer, createJob, scheduleJob } = this.props

    createCustomer(values).then(customerResponse => {
      createJob(customerResponse).then(jobResponse => {
        scheduleJob(jobResponse.data.id).then(scheduleResponse => {
          navigate('/complete')
        })
      })
    })
  }

  render() {
    const {
      classes,
      handleSubmit,
      initialValues,
      organization,
      updatedStreet,
    } = this.props

    const { streetAddress, city, state } = initialValues
    if (isBlank(streetAddress) || isBlank(city) || isBlank(state)) {
      return <Redirect to="/" noThrow />
    }

    return (
      <div>
        <Hidden mdDown>
          <form
            className={classes.form}
            onSubmit={handleSubmit(values => this.submitForm(values))}
          >
            <WebCheckoutContent
              disableForm={this.state.disableForm}
              organization={organization}
              streetAddress={updatedStreet}
            />
          </form>
        </Hidden>
        <Hidden lgUp>
          <form onSubmit={handleSubmit(values => this.submitForm(values))}>
            <MobileCheckoutContent
              disableForm={this.state.disableForm}
              streetAddress={updatedStreet}
            />
          </form>
        </Hidden>
      </div>
    )
  }
}
