import React from 'react'
import PropTypes from 'prop-types'
import {
  stateLabel,
  provinceOptions,
  stateOptions,
  OPTION_ALL,
  OPTION_CANADA,
  OPTION_USA,
} from './AddressData'
import FormTextField from './FormTextField'
import { ListSubheader, Divider, MenuItem } from '@material-ui/core'

const menuItems = options =>
  options.map(state => (
    <MenuItem value={state.value} key={state.key + state.value}>
      {state.value}
    </MenuItem>
  ))

const stateSelection = countryOption => {
  let options = []

  if (countryOption === OPTION_ALL) {
    options.push(<ListSubheader key="states">States</ListSubheader>)
    options.push(...menuItems(stateOptions))
    options.push(<Divider key="divider" />)
    options.push(<ListSubheader key="provinces">Provinces</ListSubheader>)
    options.push(...menuItems(provinceOptions))
  } else if (countryOption === OPTION_USA) {
    options = menuItems(stateOptions)
  } else {
    options = menuItems(provinceOptions)
  }

  return options
}

function StateSelect(props) {
  const { currentState, disabled, countryOption } = props

  return (
    <FormTextField
      name="state"
      label={stateLabel(currentState)}
      select
      fullWidth
      disabled={disabled}
      children={stateSelection(countryOption)}
    />
  )
}

StateSelect.propTypes = {
  countryOption: PropTypes.oneOf([OPTION_ALL, OPTION_CANADA, OPTION_USA]),
  disabled: PropTypes.bool,
}

StateSelect.defaultProps = {
  countryOption: OPTION_ALL,
  disabled: false,
}

export default StateSelect
