import React, { PureComponent } from 'react'
import InputBase from '@material-ui/core/InputBase'
import PropTypes from 'prop-types'
import { isBlank, isPresent } from '../ObjectHelper'
import { withStyles } from '@material-ui/core/styles'
import { stringifyLocation } from 'src/common/StringifyLocation'
import { withGoogleMapsAPI } from './withGoogleMapsAPI'

const componentForm = {
  street_number: 'short_name', // street_number
  route: 'short_name', // street name
  locality: 'long_name', // city
  administrative_area_level_1: 'short_name', // state
  administrative_area_level_2: 'long_name', // county
  neighborhood: 'long_name', //alternate city
  sublocality_level_1: 'long_name', //alternate city
  country: 'short_name', // country
  postal_code: 'short_name', // postalCode
}

const styles = theme => ({
  input: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

@withGoogleMapsAPI()
@withStyles(styles)
class GoogleMapsAutoComplete extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { searchValue: '', placeSelected: false, locationValue: this.props.value }

    this.id = 'googleMapsAutoComplete-' + this.props.id
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ locationValue: this.props.value })
    }
  }

  componentDidMount() {
    const input = document.getElementById(this.id)
    const options = {
      fields: ['place_id', 'name', 'types'],
    }
    const { value } = this.props

    if (window === undefined || window.google === undefined) {
      return
    }
    this.setState({ locationValue: value })
    var geoAutoComplete = new window.google.maps.places.Autocomplete(
      input,
      options
    )
    geoAutoComplete.addListener('place_changed', () => {
      var selected_place = geoAutoComplete.getPlace()
      new window.google.maps.Geocoder().geocode(
        { placeId: selected_place.place_id },
        results => {
          this.placeSelected(results[0])
        }
      )
    })
  }

  placeSelected(selectedPlace) {
    if (isBlank(selectedPlace)) {
      return
    }

    let selectedSuggest = {}

    selectedPlace.address_components.forEach(addressComponent => {
      const addressType = addressComponent.types[0]

      if (componentForm[addressType]) {
        selectedSuggest[addressType] =
          addressComponent[componentForm[addressType]]
      }
    })

    let streetName = ''
    if (isPresent(selectedSuggest.street_number)) {
      streetName = streetName.concat(selectedSuggest.street_number)
    }

    if (isPresent(selectedSuggest.route)) {
      streetName = streetName.concat(' ', selectedSuggest.route)
    }

    if (isBlank(selectedSuggest.locality)) {
      selectedPlace.address_components.forEach((addressComponent) => {
        addressComponent.types.map((type) => {
          if (type === 'sublocality_level_1') {
            selectedSuggest[type] = addressComponent[componentForm[type]]
          }
          if (type === 'neighborhood') {
            selectedSuggest[type] = addressComponent[componentForm[type]]
          }
        })
      })
    }

    let callbackObject = {
      street: streetName,
      city: selectedSuggest.locality || selectedSuggest.sublocality_level_1 || selectedSuggest.neighborhood,
      state: selectedSuggest.administrative_area_level_1,
      county: selectedSuggest.administrative_area_level_2,
      postal_code: selectedSuggest.postal_code,
      country: selectedSuggest.country,
      latitude: selectedPlace.geometry.location.lat(),
      longitude: selectedPlace.geometry.location.lng(),
    }

    this.setState({
      placeSelected: true,
      locationValue: stringifyLocation(callbackObject),
    })
    this.props.onAutoComplete(callbackObject)
  }

  render() {
    const {
      className,
      placeholder,
      autoFocus,
      label,
      classes,
      inputProps,
      disabled,
      onTextFieldChange,
    } = this.props
    const { locationValue } = this.state

    return (
      <this.props.inputComponent
        {...inputProps}
        id={this.id}
        autoFocus={autoFocus}
        className={className}
        placeholder={placeholder}
        value={locationValue}
        label={label}
        fullWidth={true}
        disabled={disabled}
        inputProps={{ className: classes.input }}
        onChange={event => {
          this.setState({ locationValue: event.target.value })
          onTextFieldChange(event.target.value)
        }}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.preventDefault()
          }
        }}
      />
    )
  }
}

GoogleMapsAutoComplete.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onAutoComplete: PropTypes.func.isRequired,
  onTextFieldChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
}

GoogleMapsAutoComplete.defaultProps = {
  placeholder: 'Enter a location',
  onTextFieldChange: () => {},
  inputComponent: InputBase,
  autoFocus: false,
  disabled: false,
}

export default GoogleMapsAutoComplete
