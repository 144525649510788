import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import FormTextField from 'src/common/Form/FormTextField'
import Grid from '@material-ui/core/Grid'
import StateSelect from 'src/common/Form/StateSelect'
import CityField from 'src/common/Form/CityField'
import GoogleMapsAutoComplete from 'src/common/GoogleMaps/GooglePlacesAutoComplete'
import TextField from '@material-ui/core/TextField'
import { change } from 'redux-form'
import { PropTypes } from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'

@connect(
  state => ({}),
  {
    change,
  }
)
export default class AddressInformation extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showGoogleAutoComplete: false,
    }
  }

  updateServiceLocation(address) {
    const { onAddressChange, change, reduxFormName } = this.props

    change(reduxFormName, 'streetAddress', address.street)
    change(reduxFormName, 'postalCode', address.postal_code)
    change(reduxFormName, 'city', address.city)
    change(reduxFormName, 'state', address.state)
    change(reduxFormName, 'latitude', address.latitude)
    change(reduxFormName, 'longitude', address.longitude)
    onAddressChange(Number(address.latitude), Number(address.longitude))
  }

  render() {
    const {
      showAdditionalNotes,
      disabled,
      reduxFormName,
      change,
      streetAddress,
    } = this.props

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <GoogleMapsAutoComplete
            label="Street address"
            name="streetAddress"
            onAutoComplete={address => {
              this.updateServiceLocation(address)
            }}
            onTextFieldChange={addressStreet => {
              change(reduxFormName, 'streetAddress', addressStreet)
            }}
            id="streetAddress"
            inputComponent={TextField}
            inputProps={{ variant: 'outlined' }}
            fullWidth
            value={streetAddress}
            autoFocus={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="streetLineTwo"
            label="Apartment, suite, unit, building, floor, etc."
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <CityField disabled={disabled} />
        </Grid>
        <Grid item xs={3}>
          <StateSelect disabled={disabled} />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            name="postalCode"
            label="Postal code"
            normalize="zip"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        {showAdditionalNotes && (
          <Grid item xs={12}>
            <FormTextField
              name="additionalNotes"
              label="What can we help you with?"
              fullWidth
              multiline
              rows={8}
              rowsMax="10"
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

AddressInformation.propTypes = {
  reduxFormName: PropTypes.string.isRequired,
  streetAddress: PropTypes.string,
  disabled: PropTypes.bool,
  showAdditionalNotes: PropTypes.bool,
  onAddressChange: PropTypes.func,
}

AddressInformation.defaultProps = {
  disabled: false,
  streetAddress: '',
  showAdditionalNotes: false,
  onAddressChange: () => null,
}
