import React from 'react'
import FormTextField from 'src/common/Form/FormTextField'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

function PersonalInformation({ disabled }) {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="firstName"
          label="First name"
          autoFocus={true}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="lastName"
          label="Last name"
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="phoneNumber"
          label="Phone number"
          normalize="phone"
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="email"
          label="E-mail"
          normalize="email"
          fullWidth
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

PersonalInformation.propTypes = {
  disabled: PropTypes.bool,
}
PersonalInformation.defaultProps = {
  disabled: false,
}
export default PersonalInformation
