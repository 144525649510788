import RestAPI from 'src/common/RestApi.js'
import { slackPhotoRequestUrl } from 'src/common/hcWebUrl'
import {
  getFullName,
  getProEmail,
  getProId,
} from 'src/common/CurrentPro/Data'
import {
  getCompanyName,
  getCompanyPhoneNumber,
  getOrgId,
} from 'src/Resources/Organization/Data'
import { getCompanyWebsite } from 'src/Resources/WebsiteContent/Data.js'

function getText(state) {
  return `Pro Name: ${getFullName(state)} \n Pro Id: ${getProId(state)} \n Pro Email: ${getProEmail(state)} \n Company Name: ${getCompanyName(state)} \n Organization Uuid: ${getOrgId(state)} \n Company Phone Number: ${getCompanyPhoneNumber(state)} \n Company Website: ${getCompanyWebsite(state)}`
}

function sendToSlack(url, text, slackMessageTitle) {
  return RestAPI.postToSlack(url, text, slackMessageTitle)
}

export function newLogoRequest() {
  return (_, getState) => {

    if (window && window.localStorage) {
      const doNotSendRequest = window.localStorage.getItem(
        'disableLogoRequestButton'
      )

      window.localStorage.setItem(
        'disableLogoRequestButton',
        'true'
      )

      if (doNotSendRequest) {
        return Promise.resolve()
      }
    }

    return sendToSlack(slackPhotoRequestUrl(), getText(getState()), 'New Logo Photo Request')
  }
}

export function newPhotoRequest() {
  return (_, getState) => {

    if (window && window.localStorage) {
      window.localStorage.setItem(
        'disableBannerRequestButton',
        'true'
      )
    }

    const productionEnv = process.env.GATSBY_ENV === 'production'
    return sendToSlack(
      'https://hooks.slack.com/services/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',
      getText(getState()),
      `${productionEnv ? '' : 'DEVELOPMENT TEST: '}New Banner Photo Request`
    )
  }
}
