import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import SummaryWithoutLineItems from './SummaryWithoutLineItems'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { CHECKOUT_FORM } from '../Checkout'

const styles = theme => ({
  containerMobile: {
    paddingLeft: 12,
    paddingRight: 12,
  },
})

@connect(state => ({
  startTime: formValueSelector(CHECKOUT_FORM)(state, 'startTime'),
  endTime: formValueSelector(CHECKOUT_FORM)(state, 'endTime'),
  streetAddress: formValueSelector(CHECKOUT_FORM)(state, 'streetAddress'),
  city: formValueSelector(CHECKOUT_FORM)(state, 'city'),
  postalCode: formValueSelector(CHECKOUT_FORM)(state, 'postalCode'),
  state: formValueSelector(CHECKOUT_FORM)(state, 'state'),
}))
class OrderSummary extends PureComponent {
  render() {
    const {
      classes,
      startTime,
      endTime,
      streetAddress,
      city,
      postalCode,
      state,
    } = this.props

    return (
      <div>
        <Hidden smDown>
          <Card>
            <CardHeader
              title={<Typography variant="h6">Order summary</Typography>}
            />
            <CardContent>
              <Grid container spacing={8}>
                <SummaryWithoutLineItems
                  startTime={startTime}
                  endTime={endTime}
                  streetAddress={streetAddress}
                  city={city}
                  postalCode={postalCode}
                  state={state}
                />
              </Grid>
            </CardContent>
          </Card>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={8} className={classes.containerMobile}>
            <Grid item xs={12}>
              <Typography variant="h6">Order summary</Typography>
            </Grid>
            <Grid item xs={12}>
              <SummaryWithoutLineItems
                startTime={startTime}
                endTime={endTime}
                streetAddress={streetAddress}
                city={city}
                postalCode={postalCode}
                state={state}
              />
            </Grid>
          </Grid>
        </Hidden>
      </div>
    )
  }
}

export default withStyles(styles)(OrderSummary)
