import { isPresent } from '../ObjectHelper'

export const OPTION_USA = 'US'
export const OPTION_CANADA = 'CA'
export const OPTION_ALL = 'ALL'

export const stateOptions = [
  { key: 0, value: 'AL' },
  { key: 1, value: 'AK' },
  { key: 2, value: 'AZ' },
  { key: 3, value: 'AR' },
  { key: 4, value: 'CA' },
  { key: 5, value: 'CO' },
  { key: 6, value: 'CT' },
  { key: 7, value: 'DE' },
  { key: 8, value: 'DC' },
  { key: 9, value: 'FL' },
  { key: 10, value: 'GA' },
  { key: 11, value: 'HI' },
  { key: 12, value: 'ID' },
  { key: 13, value: 'IL' },
  { key: 14, value: 'IN' },
  { key: 15, value: 'IA' },
  { key: 16, value: 'KS' },
  { key: 17, value: 'KY' },
  { key: 18, value: 'LA' },
  { key: 19, value: 'ME' },
  { key: 20, value: 'MD' },
  { key: 21, value: 'MA' },
  { key: 22, value: 'MI' },
  { key: 23, value: 'MN' },
  { key: 24, value: 'MS' },
  { key: 25, value: 'MO' },
  { key: 26, value: 'MT' },
  { key: 27, value: 'NE' },
  { key: 28, value: 'NV' },
  { key: 29, value: 'NH' },
  { key: 30, value: 'NJ' },
  { key: 31, value: 'NM' },
  { key: 32, value: 'NY' },
  { key: 33, value: 'NC' },
  { key: 34, value: 'ND' },
  { key: 35, value: 'OH' },
  { key: 36, value: 'OK' },
  { key: 37, value: 'OR' },
  { key: 38, value: 'PA' },
  { key: 39, value: 'RI' },
  { key: 40, value: 'SC' },
  { key: 41, value: 'SD' },
  { key: 42, value: 'TN' },
  { key: 43, value: 'TX' },
  { key: 44, value: 'UT' },
  { key: 45, value: 'VT' },
  { key: 46, value: 'VA' },
  { key: 47, value: 'WA' },
  { key: 48, value: 'WV' },
  { key: 49, value: 'WI' },
  { key: 50, value: 'WY' },
]

export const provinceOptions = [
  { key: 0, value: 'AB' },
  { key: 1, value: 'BC' },
  { key: 2, value: 'MB' },
  { key: 3, value: 'NB' },
  { key: 4, value: 'NL' },
  { key: 5, value: 'NS' },
  { key: 6, value: 'ON' },
  { key: 7, value: 'PE' },
  { key: 8, value: 'QC' },
  { key: 9, value: 'SK' },
  { key: 10, value: 'NT' },
  { key: 11, value: 'YT' },
  { key: 12, value: 'NU' },
]

export function addressCountry(currentState) {
  if (isPresent(currentState)) {
    return provinceOptions.map(x => x.value).includes(currentState)
      ? OPTION_CANADA
      : OPTION_USA
  }
  return OPTION_USA
}

export function cityLabel(state) {
  return addressCountry(state) === OPTION_CANADA ? 'Municipality' : 'City'
}

export function stateLabel(state) {
  return addressCountry(state) === OPTION_CANADA ? 'Province' : 'State'
}
