import React from 'react'
import { isBlank } from 'src/common/ObjectHelper'
import Grid from '@material-ui/core/Grid'
import { InlineDatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import InputAdornment from '@material-ui/core/InputAdornment'
import BounceSpinner from 'src/common/BounceSpinner'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  iconColor: {
    color: theme.palette.grey[600],
    marginRight: -14,
  },
})
function ServiceDateTextField({
  serviceDate,
  dateSelected,
  disabled,
  classes,
}) {
  if (isBlank(serviceDate)) {
    return <BounceSpinner />
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <InlineDatePicker
            onlyCalendar
            id="BookingCalendarDatePicker"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDown className={classes.iconColor} />
                </InputAdornment>
              ),
            }}
            disabled={disabled}
            style={{ width: '100%' }}
            label="Service date"
            disablePast
            value={serviceDate}
            onChange={date => dateSelected(date)}
            format="EEEE, MMMM do"
            leftArrowIcon={<KeyboardArrowLeft />}
            rightArrowIcon={<KeyboardArrowRight />}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

ServiceDateTextField.propTypes = {
  serviceDate: PropTypes.object,
  dateSelected: PropTypes.func,
  disabled: PropTypes.bool,
}

ServiceDateTextField.defualtProps = {
  disabled: false,
}
export default withStyles(styles)(ServiceDateTextField)
