import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  getCustomerServiceInfoStepCompleted,
  getJobSchedulingInfoStepCompleted,
} from '../Redux/Data'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import HeaderMobile from '../../Headers/HeaderMobile'
import MobileCheckoutForm from './MobileCheckoutForm'
import OrderSummary from '../OrderSummary/OrderSummary'
import { getOrganization } from 'src/Resources/Organization/Data'

const styles = theme => ({
  submitButton: {
    paddingTop: 12,
    paddingBottom: 12,
  },
})

@connect(state => ({
  customerInfoEntered: getCustomerServiceInfoStepCompleted(state),
  jobSchedulingInfoEntered: getJobSchedulingInfoStepCompleted(state),
  organization: getOrganization(state),
}))
@withStyles(styles)
export default class MobileCheckoutContent extends PureComponent {
  render() {
    const {
      classes,
      customerInfoEntered,
      jobSchedulingInfoEntered,
      disableForm,
      organization,
      streetAddress,
    } = this.props

    return (
      <Grid container spacing={40} justify="center">
        <Grid item xs={12}>
          <AppBar color="secondary" position="absolute" elevation={0}>
            <HeaderMobile
              logoUrl={organization.logo_url}
              companyName={organization.company_name}
              phoneNumber={organization.phone_number}
            />
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <MobileCheckoutForm
            disableForm={disableForm}
            streetAddress={streetAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderSummary />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            disabled={
              !(customerInfoEntered && jobSchedulingInfoEntered) || disableForm
            }
            variant="contained"
            color="primary"
            className={classes.submitButton}
            fullWidth
          >
            Complete Booking
          </Button>
        </Grid>
      </Grid>
    )
  }
}
