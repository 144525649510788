import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { isPresent } from 'src/common/ObjectHelper'

const styles = {
  icon: {
    height: 24,
    width: 24,
  },
}

function ConfirmBookingSummaryCardRow({ classes, text, ...props }) {
  if (isPresent(text)) {
    return (
      <Grid container alignItems="center" spacing={16}>
        <Grid item>
          <props.iconComponent className={classes.icon} />
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1">{text}</Typography>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

ConfirmBookingSummaryCardRow.propTypes = {
  text: PropTypes.string,
}

export default withStyles(styles)(ConfirmBookingSummaryCardRow)
