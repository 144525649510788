import { createSelector } from 'reselect'
import { receivedServerData } from 'data-fetcher'

export const BOOKING_WINDOWS_FOR_SELECTED_DATE = 'bookingWindowsForSelectedDate'

export const updateBookingWindowsState = data =>
  receivedServerData(data, BOOKING_WINDOWS_FOR_SELECTED_DATE)

export const getBookingWindowsForSelectedDate = createSelector(
  state => state[BOOKING_WINDOWS_FOR_SELECTED_DATE],
  bookingWindowsForSelectedDate => {
    return bookingWindowsForSelectedDate || []
  }
)

export const getServiceDate = createSelector(
  getBookingWindowsForSelectedDate,
  bookingWindowsForSelectedDate => {
    return bookingWindowsForSelectedDate.serviceDate || null
  }
)

export const getBookingWindows = createSelector(
  getBookingWindowsForSelectedDate,
  bookingWindowsForSelectedDate => {
    return bookingWindowsForSelectedDate.bookingWindows || []
  }
)
