import { isBlank, isPresent } from '../ObjectHelper'

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const validatePresence = value =>
  isBlank(value) ? 'field required' : null

export const isEmail = value => {
  if (isBlank(value)) {
    return true
  } else if (value.length > 255) {
    return false
  } else {
    let emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
    return emailRegex.test(value)
  }
}

export const isPhone = value => {
  if (isBlank(value)) {
    return true
  } else {
    let phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
    return phoneRegex.test(value)
  }
}

export const isNumber = value => {
  if (isBlank(value)) {
    return true
  } else {
    return !isNaN(parseFloat(value)) && isFinite(value)
  }
}

export const fieldLengthFormValidator = (value, allowableLength) => {
  if (isPresent(value) && value.length > allowableLength) {
    return 'Text is too long'
  }

  return null
}

export const phoneNumberFormValidator = phoneNumber => {
  if (!isPhone(phoneNumber)) {
    return 'This doesn’t look like a valid phone number'
  }

  return null
}

export const emailFormValidator = email => {
  if (isPresent(email)) {
    email = email.trim()

    if (!isEmail(email)) {
      return 'This doesn’t look like a valid email address'
    }
  }

  return null
}

export const radiusFormValidator = radius => {
  if (!isNumber(radius)) {
    return 'This doesn’t look like a valid number'
  }

  return null
}
