import React, { PureComponent } from 'react'
import ExecutionEnvironment from '../ExecutionEnvironment'

export function withGoogleMapsAPI() {
  return WrappedComponent => {
    class WrappedComponentWithGoogleMapsAPI extends PureComponent {
      constructor(props) {
        super(props)

        this.state = {
          googleLoaded: false,
        }
      }

      componentDidMount() {
        if (typeof google !== 'object') {
          var script = document.createElement('script')
          script.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${
            process.env.GOOGLE_MAPS_API_KEY
          }&libraries=places`
          script.addEventListener('load', () => {
            this.setState({ googleLoaded: true })
          })
          script.addEventListener('error', () => {})
          document.body.appendChild(script)
        } else {
          this.setState({ googleLoaded: true })
        }
      }

      render() {
        if (this.state.googleLoaded || ExecutionEnvironment.onServer) {
          return <WrappedComponent {...this.props} />
        } else {
          return null
        }
      }
    }

    return WrappedComponentWithGoogleMapsAPI
  }
}
