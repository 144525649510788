import React from 'react'
import { TextField } from '@material-ui/core'
import { Field } from 'redux-form'
import { isPresent } from '../ObjectHelper'

const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
}

const normalizeZip = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length > 5) {
        return onlyNums.slice(0, 5)
    }

    return value
}

const FormTextField = props => {
    const { normalize, focusOnNextFieldUponEnter, ...theRest } = props

    var normalizer
    if (normalize === 'phone') {
        normalizer = normalizePhone
    } else if (normalize === 'zip') {
        normalizer = normalizeZip
    }

    var handleKeyDown
    if (focusOnNextFieldUponEnter) {
        handleKeyDown = event => {
            if (event.keyCode === 13) {
                const form = event.target.form
                const index = Array.prototype.indexOf.call(form, event.target)
                const nextElement = form.elements[index + 2]
                if (nextElement instanceof HTMLInputElement && nextElement.type === 'text') {
                    event.preventDefault()
                    form.elements[index + 2].focus()
                }
            }
        }
    }

    return <Field component={renderTextField} normalize={normalizer} onKeyDown={handleKeyDown} {...theRest} />
}

const renderTextField = ({ input, fullWidth, helperText, meta: { touched, error }, ...custom }) => {
    return (
        <TextField
            fullWidth={isPresent(fullWidth) ? fullWidth : false}
            error={touched && isPresent(error)}
            variant='outlined'
            helperText={touched && (typeof error === 'string' && error.length > 0) ? error : helperText || ''}
            {...custom}
            {...input}
        />
    )
}

export default FormTextField
