import RestAPI from 'src/common/RestApi'

export function createJobApi(jobData) {
  return RestAPI.postWithFormData('/website_builder/jobs', jobData)
}

export function createCustomerApi(customerData) {
  return RestAPI.postWithFormData('/alpha/customers', customerData)
}

export function scheduleJobApi(jobUuid, scheduleData) {
  return RestAPI.updateWithFormData(
    '/website_builder/jobs/' + jobUuid + '/schedule',
    scheduleData
  )
}
