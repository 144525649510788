import React from 'react'
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { formatStartAndEndTime } from 'src/common/StartEndDateTimeParser'
import BounceSpinner from 'src/common/BounceSpinner'
import { isPresent } from 'src/common/ObjectHelper'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  disabled: {
    backgroundColor: theme.palette.grey[200],
  },
})

function BookingWindowsSelect({
  onWindowSelected,
  selectedBookingWindow,
  bookingWindows,
  disabled,
  classes,
}) {
  if (bookingWindows === null) {
    return <BounceSpinner />
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Service time</InputLabel>
      <Select
        disabled={disabled || bookingWindows.length < 2}
        className={bookingWindows.length < 2 ? classes.disabled : ''}
        value={selectedBookingWindow}
        onChange={event => onWindowSelected(event.target.value)}
        input={<OutlinedInput labelWidth={88} />}
        renderValue={bookingWindowValue => {
          if (isPresent(bookingWindowValue)) {
            return formatStartAndEndTime(
              bookingWindowValue.start_time,
              bookingWindowValue.end_time
            )
          } else {
            return 'No time available'
          }
        }}
      >
        {bookingWindows.map((bookingWindow, index) => (
          <MenuItem
            key={index}
            value={bookingWindow}
            disabled={bookingWindow.available === false}
          >
            {formatStartAndEndTime(
              bookingWindow.start_time,
              bookingWindow.end_time
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

BookingWindowsSelect.propTypes = {
  onWindowSelected: PropTypes.func.isRequired,
  selectedBookingWindow: PropTypes.object,
  bookingWindows: PropTypes.array,
  disabled: PropTypes.bool,
}

BookingWindowsSelect.defaultProps = {
  disabled: false,
}

export default withStyles(styles)(BookingWindowsSelect)
