import React, { Fragment } from 'react'
import { Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
  uploadButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  input: {
    display: 'none',
  },
})

function UploadButton({ classes, onChange, id }) {
  return (
    <Fragment>
      <input
        accept="image/*"
        className={classes.input}
        id={id}
        type="file"
        onChange={event => (event.preventDefault(), onChange(event))}
      />
      <label htmlFor={id}>
        <Button variant="outlined" color="primary" component="span">
          Upload
        </Button>
      </label>
    </Fragment>
  )
}

UploadButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

export default withStyles(styles)(UploadButton)
