import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import UploadButton from './UploadButton'
import { Grid } from '@material-ui/core'

const styles = {
  container: {
    maxHeight: 400,
  },
  image: {
    maxHeight: 300,
    width: '100%',
    objectFit: 'contain',
  },
  uploadButton: {
    textAlign: 'center',
    marginTop: 16,
  },
}

function UploadImage({ classes, imageUrl, onChange, id }) {
  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <img className={classes.image} src={imageUrl} />
      </Grid>
      <Grid item xs={12} className={classes.uploadButton}>
        <UploadButton onChange={event => onChange(event)} id={id} />
      </Grid>
    </Grid>
  )
}

UploadImage.propTypes = {
  imageUrl: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
}

export default withStyles(styles)(UploadImage)
