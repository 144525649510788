import { isBlank } from './ObjectHelper.js'

export function format(phoneNumber) {
  if (isBlank(phoneNumber) || phoneNumber.length !== 10) {
    return phoneNumber
  }
  var formattedPhoneNumber = phoneNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3'
  )
  return formattedPhoneNumber
}
