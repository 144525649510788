import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

export const TOP_POSITION_ON_OVERLAY = -120

const styles = theme => ({
  rightPane: {
    position: 'sticky',
    height: 1,
    zIndex: 2,
    width: 500,
  },
  regularPosition: {
    top: -1 * TOP_POSITION_ON_OVERLAY,
  },
  overlayPosition: {
    top: `calc(140px + ${-1 * TOP_POSITION_ON_OVERLAY}px)`,
  },
  container: {
    width: `calc(100% + 16px)`,
  },
  leftPane: {
    maxWidth: 710
  }
})

const SplitPaneStickyRight = ({
  classes,
  leftPane,
  rightPane,
  rightPaneHasNegativePosition,
}) => {
  return (
    <Grid container spacing={40} className={classes.container}>
      <Grid item xs className={classes.leftPane}>
        {leftPane}
      </Grid>
      <Grid
        item
        className={classNames(
          classes.rightPane,
          rightPaneHasNegativePosition
            ? classes.overlayPosition
            : classes.regularPosition
        )}
      >
        {rightPane}
      </Grid>
    </Grid>
  )
}

SplitPaneStickyRight.propTypes = {
  leftPane: PropTypes.element.isRequired,
  rightPane: PropTypes.element.isRequired,
  rightPaneHasNegativePosition: PropTypes.bool,
}

SplitPaneStickyRight.defaultProps = {
  rightPaneHasNegativePosition: false,
}

export default withStyles(styles)(SplitPaneStickyRight)
