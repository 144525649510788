import React from 'react'
import Header from '../../Headers/Header'
import Grid from '@material-ui/core/Grid'
import SplitPaneStickyRight from 'src/common/SplitPaneStickyRight'
import WebCheckoutForm from '../Web/WebCheckoutForm'
import OrderSummary from '../OrderSummary/OrderSummary'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: theme.spacing.centerContentMaxWidth,
  },
})

const WebCheckoutContent = ({
  classes,
  organization,
  disableForm,
  streetAddress,
}) => {
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Header
          logoUrl={organization.logo_url}
          companyName={organization.company_name}
          phoneNumber={organization.phone_number}
          showActions={false}
          showBookOnline={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <SplitPaneStickyRight
          leftPane={
            <WebCheckoutForm
              disableForm={disableForm}
              streetAddress={streetAddress}
            />
          }
          rightPane={<OrderSummary />}
        />
      </Grid>
    </Grid>
  )
}

WebCheckoutContent.propTypes = {
  organization: PropTypes.object,
}

export default withStyles(styles)(WebCheckoutContent)
