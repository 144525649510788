import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {
  getCustomerServiceInfoStepCompleted,
  getJobSchedulingInfoStepCompleted,
} from '../Redux/Data'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import PersonalInformation from '../CustomerServiceInformation/PersonalInformation'
import AddressInformation from '../CustomerServiceInformation/AddressInformation'
import Section from '../Sections/Section'
import BookingWindowsSelection from '../BookingWindowsSelection/BookingWindowsSelection'
import { CHECKOUT_FORM } from '../Checkout'
import BounceSpinner from 'src/common/BounceSpinner'

const styles = theme => ({
  card: {
    padding: 12,
  },
  submitButtonGrid: {
    marginLeft: 48,
    marginRight: 48,
  },
  submitButton: {
    paddingTop: 12,
    paddingBottom: 12,
  },
})
@connect(state => ({
  customerInfoEntered: getCustomerServiceInfoStepCompleted(state),
  jobSchedulingInfoEntered: getJobSchedulingInfoStepCompleted(state),
}))
@withStyles(styles)
export default class WebCheckoutContent extends PureComponent {
  render() {
    const {
      classes,
      customerInfoEntered,
      jobSchedulingInfoEntered,
      disableForm,
      streetAddress,
    } = this.props
    return (
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={16} spacing={24}>
            <Grid item xs={12}>
              <Section
                sectionNumber={1}
                title="Enter your contact information"
                content={<PersonalInformation disabled={disableForm} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Section
                sectionNumber={2}
                title="Select your preferred appointment time"
                content={<BookingWindowsSelection disabled={disableForm} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Section
                sectionNumber={3}
                title="Service information"
                content={
                  <AddressInformation
                    disabled={disableForm}
                    reduxFormName={CHECKOUT_FORM}
                    showAdditionalNotes={true}
                    streetAddress={streetAddress}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.submitButtonGrid}>
              <Button
                type="submit"
                disabled={
                  !(customerInfoEntered && jobSchedulingInfoEntered) ||
                  disableForm
                }
                variant="contained"
                color="primary"
                className={classes.submitButton}
                fullWidth
              >
                {disableForm ? <BounceSpinner /> : 'Complete Booking'}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}
