import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SectionHeader from './SectionHeader'

const styles = {
  contentDesktop: {
    paddingLeft: 48,
    paddingRight: 48,
    paddingTop: 16,
  },
  contentMobile: {
    paddingTop: 16,
  },
}
function Section({ classes, title, content, sectionNumber, isMobile }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionHeader
          sectionNumber={sectionNumber}
          title={title}
          variant="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={isMobile ? classes.contentMobile : classes.contentDesktop}
      >
        {content}
      </Grid>
    </Grid>
  )
}

Section.propTypes = {
  sectionNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}

Section.defaultProps = {
  isMobile: false,
}

export default withStyles(styles)(Section)
